























































































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class FormFields extends Vue {
  checks = []
  actionSubmit () {
    console.log('Submit')
  }
}

