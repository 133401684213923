

































































































import { Component, Vue } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Save16 from '@carbon/icons-vue/es/save/16'
import Download16 from '@carbon/icons-vue/es/download/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'

@Component({
  components: {
    TrashCan16,
    Save16,
    Download16,
    Settings16,
    Edit16
  }
})
export default class DataTable extends Vue {
  columns = ['Data', 'Modelo', 'Tipo', 'Serviço']
  filteredData = [
    {
      id: 1,
      data: '02/05/2020',
      modelo: '2020-02',
      tipo: 'Prova',
      servico: 'Hospital XYZ BSB'
    },
    {
      id: 2,
      data: '04/05/2020',
      modelo: '2020-02',
      tipo: 'Simulado',
      servico: 'Hospital XYZ BSB'
    }
  ]
}
