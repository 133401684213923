






































import { Component, Prop, Mixins } from 'vue-property-decorator'

import PermissionsMixin from '@/mixins/permissionsMixin'

@Component
export default class Card extends Mixins(PermissionsMixin) {
  @Prop({ default: '', type: Boolean }) lightTheme!: string
  @Prop({ default: '' }) satisfaction!: number
  @Prop({ default: '' }) serviceId!: number
  @Prop({ default: '' }) kind!: string
  @Prop() imageSrc!: string

  get tagClass () {
    let color = ''
    if (this.satisfaction < 2.5) {
      color = 'bg-ibm-red-30'
    }

    if (this.satisfaction >= 2.5) {
      color = 'bg-ibm-yellow-30'
    }

    if (this.satisfaction >= 3.5) {
      color = 'bg-ibm-green-30'
    }

    if (this.satisfaction >= 4.5) {
      color = 'bg-ibm-blue-30'
    }

    return `${color} text-gray-800 m-4`
  }
}
