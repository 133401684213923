import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import PERMISSIONS from '@/helpers/permissions'

const permissionsModule = namespace('userPermissions')
const servicesPermissionsModule = namespace('servicesPermissions')
const eventsPermissionsModule = namespace('eventsPermissions')

@Component
export default class PermissionsMixin extends Vue {
  @permissionsModule.Getter
  hasPermission!: (permission: string) => boolean

  @servicesPermissionsModule.Getter('hasPermission')
  hasServicePermission!: (serviceId: string, permission: string) => boolean

  @servicesPermissionsModule.Getter('getServiceUserId')
  getServiceUserId!: (serviceId: string) => boolean | string

  @servicesPermissionsModule.Getter
  hasPermissionOnAnyService!: (permission: string) => boolean

  @eventsPermissionsModule.Getter('hasPermission')
  hasEventPermission!: (eventId: string, permission: string) => boolean

  @eventsPermissionsModule.Getter('hasStudyPermission')
  hasStudyPermission!: (eventId: string, permission: string) => boolean

  permissions = PERMISSIONS
}
