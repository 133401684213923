











































































import { Component, Vue } from 'vue-property-decorator'
import Edit16 from '@carbon/icons-vue/es/edit/16'

@Component({
  components: {
    Edit16
  }
})
export default class Buttons extends Vue {

}

