











































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Modal extends Vue {
  @Prop() private visibleProp!: boolean
  closeAriaLabel = 'Fechar'
  visible = false
  size = ''
  useContentWithInput = true
  useSecondaryButton = true
  usePrimaryButton = true
  primaryButtonDisabled = false
  autoHideOff = false

  created () {
    this.visible = false
  }

  actionPrimary () {
    this.visible = false
  }

  actionSecondary () {
    this.visible = false
  }
}
