








































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Notifications extends Vue {
  closeIt () {
    console.log('Fecha')
  }
}
