













































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class RegionalsSideNav extends Vue {
  @Prop() value!: Array<string>

  selectedUFs: Array<string> = []
  allUFIds: Array<string> = []
  regionals = []

  created () {
    axios.get('services/regionals')
      .then((response) => {
        this.regionals = response.data.data
      })
  }

  @Watch('regionals')
  onRegionalsChange () {
    if (this.regionals !== undefined) {
      this.allUFIds = this.regionals.reduce((acc: Array<string>, regional: Regional) => {
        return acc.concat(regional.services.map(service => service.id))
      }, [])
    }
  }

  @Watch('value', { immediate: true })
  onValueChange () {
    const model = [...this.value]

    this.selectedUFs = model
  }

  selectAllFromRegional ($e: boolean, regional: Regional) {
    const regionalUFIds = regional.services.map((service: ServiceRegion) => service.id)

    if ($e === true) {
      regionalUFIds.forEach((id: string) => {
        if (!this.selectedUFs.includes(id)) {
          this.selectedUFs.push(id)
        }
      })
    } else {
      this.selectedUFs = this.selectedUFs.filter(id => !regionalUFIds.includes(id))
    }
  }

  selectAllUFs () {
    if (this.selectedUFs.length === this.allUFIds.length) {
      this.selectedUFs = []
    } else {
      this.selectedUFs = [...this.allUFIds]
    }
  }
}
