<template>
  <div>
    <cv-tag
      kind="red"
      label="Red"
      filter
      clear-aria-label="Remover tag"
    />
    <cv-tag kind="magenta" label="Magenta" />
    <cv-tag kind="purple" label="Purple" />
    <cv-tag kind="blue" label="Blue" />
    <cv-tag kind="cyan" label="Cyan" />
    <cv-tag kind="teal" label="Teal" />
    <cv-tag kind="green" label="Green" />
    <cv-tag kind="gray" label="Gray" />
    <cv-tag kind="cool-gray" label="Cool gray" />
    <cv-tag kind="warm-gray" label="Warm gray" />
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
