






































































































import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/partials/components/Navbar.vue'
import DataTable from '@/partials/components/TestSandbox/DataTable.vue'
import RegionalsSideNav from '@/partials/components/RegionalsSideNav.vue'
import Modal from '@/partials/components/TestSandbox/Modal.vue'
import Card from '@/partials/components/ServiceOverviewCard.vue'
import Buttons from '@/partials/components/TestSandbox/Buttons.vue'
import Notifications from '@/partials/components/TestSandbox/Notifications.vue'
import Tags from '@/partials/components/TestSandbox/Tags.vue'
import FormFields from '@/partials/components/TestSandbox/FormFields.vue'

@Component({
  components: {
    Navbar,
    RegionalsSideNav,
    DataTable,
    Modal,
    Card,
    Buttons,
    Notifications,
    Tags,
    FormFields
  }
})
export default class Components extends Vue {
  container = true
  visibleModal = false

  actionSelected () {
    console.log('Troca de tab')
  }

  openModal () {
    this.visibleModal = true
  }
}
