



























import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Navbar from '@/partials/components/Navbar.vue'

const sessionModule = namespace('session')
const flashMessageModule = namespace('flashMessage')

@Component({
  components: {
    Navbar
  }
})

export default class MainLayout extends Vue {
  @sessionModule.Getter state!: Record<string, string>
  @sessionModule.Getter isCommonUser!: boolean
  @sessionModule.Getter getUploadedTEOT!: string
  @flashMessageModule.Getter getFlashMessage!: flashMessageState
  @flashMessageModule.Action setMessage!: Function
}
