























































import { Component, Mixins } from 'vue-property-decorator'
import UserAvatar20 from '@carbon/icons-vue/es/user--avatar/20'
import { namespace } from 'vuex-class'
import { directive as onClickaway } from 'vue-clickaway'

import PermissionsMixin from '@/mixins/permissionsMixin'
import NavbarHelper from '@/helpers/navbar'

const sessionModule = namespace('session')

@Component({
  components: {
    UserAvatar20
  },
  directives: {
    onClickaway
  }
})
export default class Navbar extends Mixins(PermissionsMixin) {
  @sessionModule.Action('logout') logoutAction!: Function
  @sessionModule.Getter hasSomeRole!: Function
  @sessionModule.Getter hasSomeServiceRole!: Function
  @sessionModule.Getter getNavbarItems!: Function
  NAVBAR = NavbarHelper

  isNavbarExpanded = false
  hasPaddingTop = true

  created () {
    window.addEventListener('scroll', this.handleScroll)
  }

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll () {
    this.hasPaddingTop = window.scrollY < 5
  }

  get navbarItems () {
    return this.getNavbarItems
  }

  navbarRoute (key: keyof typeof NavbarHelper) {
    return this.NAVBAR[key]
  }

  closeNavbar () {
    this.isNavbarExpanded = false
  }

  logout () {
    this.logoutAction()
      .finally(() => {
        this.$router.push({ name: 'Login' })
      })
  }
}
